body {
  text-align: center;
  font-size: 1em;
  margin: 0;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
}
h1 {
  font-size: 1.2em;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  margin: 0;
  color: #333;
}
/* rows */
.row {
  text-align: center;
  display: flex;
  justify-content: center;
}
.row > div {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #bbb;
  margin: 4px;
  text-align: center;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
  padding: 0px;
}
.row > div.green {
  --background: #5ac85a;
  --border-color: #5ac85a;
  animation: flip 0.5s ease forwards;
}
.row > div.grey {
  --background: #a1a1a1;
  --border-color: #a1a1a1;
  animation: flip 0.6s ease forwards;
}
.row > div.yellow {
  --background: #eac312;
  --border-color: #eac312;
  animation: flip 0.5s ease forwards;
}
.row > div.green1 {
  background: #5ac85a;
  border-color: #5ac85a;
  color: #eee;
}
.row > div.grey1 {
  background: #a1a1a1;
  border-color: #a1a1a1;
  color: #eee;
}
.row > div.yellow1 {
  background: #eac312;
  border-color: #eac312;
  color: #eee;
}
.row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.row > div:nth-child(3) {
  animation-delay: 0.4s;
}
.row > div:nth-child(4) {
  animation-delay: 0.6s;
}
.row > div:nth-child(5) {
  animation-delay: 0.8s;
}
.row.current > div.filled {
  animation: bounce 0.2s ease-in-out forwards;
}
.row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.row > div:nth-child(3) {
  animation-delay: 0.4s;
}
.row > div:nth-child(4) {
  animation-delay: 0.6s;
}
.row > div:nth-child(5) {
  animation-delay: 0.8s;
}
.row > div.load {
  animation: bounce2 0.2s ease-in-out forwards;
}
.row > div.load:nth-child(2) {
  animation-delay: 0.1s;
}
.row > div.load:nth-child(3) {
  animation-delay: 0.2s;
}
.row > div.load:nth-child(4) {
  animation-delay: 0.3s;
}
.row > div.load:nth-child(5) {
  animation-delay: 0.4s;
}
.keypad {
  max-width: 500px;
  margin: 10px auto;
}
.keypad > div {
  margin: 3px;
  width: 30px;
  height: 40px;
  background: #eee;
  display: inline-block;
  border-radius: 6px;
  line-height: 40px;
  text-transform: capitalize;
}
.keypad > div:hover {
 transform: scale(1.1);
}
.keypad > div.green {
  background: #5ac85a;
  color: #fff;
  transition: all 0.3s ease-in;
}
.keypad > div.yellow {
  background: #eac312;
  color: #fff;
  transition: all 0.3s ease-in;
}
.keypad > div.grey {
  background: #a1a1a1;
  color: #fff;
  transition: all 0.3s ease-in;
}
.modal2 {
  background: rgba(255,255,255,0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
}
.modal2> div {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
.modal2>div>div{
  justify-content: space-evenly;
    display: flex;
}
.modal2 .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.timer{
  position: absolute;
  top:0;
  right: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #07ff4d;
  padding: 5px;
  margin: 5px;
}
.timer.ani{
  animation: colorchange 300s linear;
}
@media(max-width:1000px){
  .timer{
    right: 40%;
  }
}

.funK {
  width: 50px!important;
}

.clicked{
  animation: bounce 0.2s ease-in-out forwards;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.fadeIn {
  animation: fadeInAnimation 0.5s linear;
}

.fadeOut {
  animation: fadeOutAnimation 0.5s linear;
}

@keyframes colorchange {
  0% {
    background: #07ff4d;
  }
  20% {
    background: #b5ff07;
  }
  40% {
    background: #daff07;
  }
  60% {
    background: #FECF87;
  }
  80% {
    background: #fd6202;
  }
  100% {
    background: #ff0d00;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAnimation {
  0% {
    top: 0%;
  }
  100% {
    top:-100%;
    
  }
}

/* keyframe animations */
@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #333;
  }
  45% {
    transform: rotateX(90deg);
    background: white;
    border-color: #333;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
  }
  100% {
    transform: rotateX(0deg);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee;
  }
}

@keyframes bounce {
  0% { 
    transform: scale(1);
    border-color: #ddd;
  }
  50% { 
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    border-color: #333;
  }
}
@keyframes bounce2 {
  0% { 
    transform: scale(1);
  }
  50% { 
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}